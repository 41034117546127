import { Container } from "@chakra-ui/react";
import { Home } from "Home";
import Login from "Login";
import Register from "Register";
import { Loading } from "components";
import { useAuthContext } from "hooks";
import LivePOCStreamPage from "pocstream/pages/LivePOCStreamPage";
import React from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";

const LoadingFallback = () => {
  return (
    <Container>
      <Loading text="" />
    </Container>
  );
};

const CasualAnalysisRedirect = () => {
  const { id } = useParams<{ id: string }>();
  return <Navigate to={`/view-casualanalysis/${id}`} replace />;
};

export const Screens = () => {
  const { isAuthenticated, loading } = useAuthContext();
  if (loading) {
    return <Loading text="Logging you in" size={180} />;
  }

  return isAuthenticated ? (
    <Home />
  ) : (
    <Routes>
      <Route
        path="/"
        element={
          <React.Suspense fallback={<LoadingFallback />}>
            <Login />
          </React.Suspense>
        }
      />

      <Route
        path="/register"
        element={
          <React.Suspense fallback={<LoadingFallback />}>
            <Register />
          </React.Suspense>
        }
      />

      <Route
        path="/view-casualanalysis/:id"
        element={
          <React.Suspense fallback={<LoadingFallback />}>
            <LivePOCStreamPage />
          </React.Suspense>
        }
      />

      <Route
        path="/view-casualanalysis/:id/*"
        element={<CasualAnalysisRedirect />}
      />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
