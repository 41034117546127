import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  useColorModeValue,
  FormErrorMessage,
  Text,
  useToast,
  Link,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { register as registerAPI } from "proto/auth/v1/auth-AuthAPI_connectquery";
import { getRoutePath } from "./helpers";
import { useMutation } from "@tanstack/react-query";

type RegisterForm = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
};

export default function Register() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<RegisterForm>({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const toast = useToast();
  const navigate = useNavigate();

  const { mutate, isLoading: mutationLoading } = useMutation({
    ...registerAPI.useMutation(),
    onSuccess: () => {
      toast({
        title: "Account Created. You can now Login.",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
      });

      navigate(getRoutePath("/"), {
        replace: true,
      });
    },
    onError: () => {
      toast({
        title: "Error while creating an Account",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    },
  });
  const onSubmit = async (data: RegisterForm) => {
    mutate({
      ...data,
    });
  };

  const passwordEntered = watch("password");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex
        align={"center"}
        justify={"center"}
        bg={useColorModeValue("gray.50", "gray.800")}
        h={{ base: "100%", md: "100vh" }}
      >
        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6} minW={"35vw"}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"}>Sign up for a account</Heading>
            {/* <Text fontSize={"lg"} color={"gray.600"}>
            to enjoy all of our cool <Link color={"blue.400"}>features</Link> ✌️
          </Text> */}
          </Stack>
          <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"lg"}
            p={8}
          >
            <Stack spacing={4}>
              <FormControl id="firstName" isInvalid={!!errors?.firstName}>
                <FormLabel>First Name</FormLabel>
                <Input
                  isDisabled={isSubmitting || mutationLoading}
                  type="firstName"
                  {...register("firstName", {
                    required: "Required",
                  })}
                />
                <FormErrorMessage>
                  {errors?.firstName && errors?.firstName?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl id="lastName" isInvalid={!!errors?.lastName}>
                <FormLabel>Last Name</FormLabel>
                <Input
                  isDisabled={isSubmitting || mutationLoading}
                  type="lastName"
                  {...register("lastName", {
                    required: "Required",
                  })}
                />
                <FormErrorMessage>
                  {errors?.lastName && errors?.lastName?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl id="email" isInvalid={!!errors?.email}>
                <FormLabel>Email address</FormLabel>
                <Input
                  type="email"
                  {...register("email", {
                    required: "Required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Invalid Email",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors?.email && errors?.email?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl id="password" isInvalid={!!errors?.password}>
                <FormLabel>Password</FormLabel>
                <Input
                  isDisabled={isSubmitting || mutationLoading}
                  type="password"
                  {...register("password", {
                    required: "Required",
                    minLength: {
                      value: 6,
                      message: "Min 6 characters Required",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors?.password && errors?.password?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id="confirmPassword"
                isInvalid={!!errors?.confirmPassword}
              >
                <FormLabel>Renter Password</FormLabel>
                <Input
                  type="password"
                  {...register("confirmPassword", {
                    validate: (v) =>
                      v === passwordEntered || "The passwords do not match",
                  })}
                />
                <FormErrorMessage>
                  {errors?.confirmPassword && errors?.confirmPassword?.message}
                </FormErrorMessage>
              </FormControl>
              <Stack spacing={2}>
                <Button
                  isLoading={isSubmitting || mutationLoading}
                  type="submit"
                  /* bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.500",
                }} */
                >
                  Register
                </Button>
                <Flex justify={"center"} pt={4}>
                  Already have an account?{" "}
                  <Link px={1} color={"blue.400"} as={RouterLink} to="/">
                    Log in
                  </Link>
                </Flex>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </form>
  );
}
