import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useStateContext } from "./state";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  getRecording,
  sendLiveRecordingCommands,
} from "proto/recording/v1/recording_api-RecordingAPI_connectquery";
import { serveBasePath } from "server-props";
import { SendLiveRecordingCommandsRequest } from "proto/recording/v1/recording_api_pb";
import { CommandType } from "proto/recording/v1/recording_api_pb";
import { useState } from "react";
import { Loading } from "components";
import { useNavigate } from "react-router-dom";

export default function ControlLiveRecording() {
  const { recording } = useStateContext();
  const recordingInfo = recording?.info!;
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();

  const { mutate: commandMutate, isLoading: commandIsLoading } = useMutation({
    ...sendLiveRecordingCommands.useMutation({}),
    onSuccess: (resp) => {
      queryClient.invalidateQueries(
        getRecording.getQueryKey({ id: recording?.info?.id ?? "" })
      );

      if (resp.info?.hasEnded) {
        toast({
          status: "success",
          title: "Live Match Ended!",
          description: "",
        });

        navigate(`/recording/${recordingInfo.id}`, { replace: true });
      }
    },
  });

  const [isPlayerPosInverted, setIsPlayerPosInverted] = useState(false);
  const [frameNo] = useState(-1);

  const games = recordingInfo.liveGames ?? [];
  const videoUrl = recordingInfo.isLive
    ? `${serveBasePath}/recording/camera/${recordingInfo?.id}`
    : `${serveBasePath}/api/files/recordings/${recordingInfo?.id}/upload.mp4`;

  if (commandIsLoading) return <Loading text="Updating..." />;

  if (recordingInfo.hasEnded) {
    return (
      <Center>
        <Text size={"lg"}>Recording Ended</Text>
      </Center>
    );
  }

  return (
    <>
      {recordingInfo.isLive === true && (
        <img
          height="100%"
          width="100%"
          src={videoUrl}
          style={{ borderRadius: 4 }}
          alt="Live"
        />
      )}
      <Stack pt={4}>
        <Center>
          <Box>
            <FormControl>
              <FormLabel fontSize="sm" fontWeight="md">
                Is Player position inverted?
              </FormLabel>
              <RadioGroup
                value={isPlayerPosInverted ? "yes" : "no"}
                onChange={(v) => {
                  setIsPlayerPosInverted(v === "yes");
                }}
                py={2}
              >
                <Stack direction="row">
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
          </Box>
        </Center>

        <Box>
          {games.length > 0 && games[games.length - 1].rootEndFrame !== -1 && (
            <Button
              size="lg"
              w="full"
              colorScheme="red"
              onClick={() => {
                commandMutate(
                  new SendLiveRecordingCommandsRequest({
                    command: {
                      type: CommandType.END_MATCH,
                      frameNo: frameNo,
                      isPlayerPosInverted,
                    },
                    id: recordingInfo.id,
                  })
                );
              }}
            >
              End Match
            </Button>
          )}
        </Box>

        <Box>
          {(games.length === 0 ||
            games[games.length - 1].rootEndFrame !== -1) && (
            <Button
              size="lg"
              w="full"
              onClick={() => {
                commandMutate(
                  new SendLiveRecordingCommandsRequest({
                    command: {
                      type: CommandType.START_GAME,
                      frameNo: frameNo,
                      isPlayerPosInverted,
                    },
                    id: recordingInfo.id,
                  })
                );
              }}
              colorScheme="green"
            >
              Start Game {games.length + 1}
            </Button>
          )}
        </Box>

        <Box>
          {games.length > 0 &&
            games[games.length - 1].rootEndFrame === -1 &&
            games[games.length - 1]?.g1?.rootEndFrame === -1 && (
              <Button
                size="lg"
                w="full"
                onClick={() => {
                  commandMutate(
                    new SendLiveRecordingCommandsRequest({
                      command: {
                        type: CommandType.END_G1,
                        frameNo: frameNo,
                        isPlayerPosInverted,
                      },
                      id: recordingInfo.id,
                    })
                  );
                }}
                colorScheme="orange"
              >
                End Game {games.length} 1st Half
              </Button>
            )}
        </Box>

        <Box>
          {games.length > 0 &&
            games[games.length - 1].rootEndFrame === -1 &&
            games[games.length - 1]?.g1?.rootEndFrame !== -1 &&
            !games[games.length - 1]?.g2 && (
              <Button
                size="lg"
                w="full"
                onClick={() => {
                  commandMutate(
                    new SendLiveRecordingCommandsRequest({
                      command: {
                        type: CommandType.START_G2,
                        frameNo: frameNo,
                        isPlayerPosInverted,
                      },
                      id: recordingInfo.id,
                    })
                  );
                }}
              >
                Start Game {games.length} 2nd Half
              </Button>
            )}
        </Box>

        <Box>
          {games.length > 0 &&
            games[games.length - 1].rootEndFrame === -1 &&
            games[games.length - 1]?.g1?.rootEndFrame !== -1 &&
            games[games.length - 1]?.g2?.rootEndFrame === -1 && (
              <Button
                size="lg"
                w="full"
                onClick={() => {
                  commandMutate(
                    new SendLiveRecordingCommandsRequest({
                      command: {
                        type: CommandType.END_GAME,
                        frameNo: frameNo,
                        isPlayerPosInverted,
                      },
                      id: recordingInfo.id,
                    })
                  );
                }}
                colorScheme="red"
              >
                End Game - {games.length}
              </Button>
            )}
        </Box>
      </Stack>
    </>
  );
}
