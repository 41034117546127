import {
  SimpleGrid,
  GridItem,
  Box,
  Heading,
  chakra,
  Stack,
  FormControl,
  FormLabel,
  Button,
  Text,
  useColorModeValue,
  Input,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { CreatePOCStreamRequest } from "proto/pocstream/v1/poc_api_pb";
import { Loading } from "components";
import { useStateContext } from "./state";
import { useNavigate } from "react-router-dom";
import { getRoutePath } from "helpers";
import { createPOCStream } from "proto/pocstream/v1/poc_api-POCStreamAPI_connectquery";
import { useWizard } from "react-use-wizard";

interface SettingsForm {
  velocityGraphInterval: number;
  distanceCoverageGraphInterval: number;
  movementPatternSize: number;
  movementPatternInterval: number;
  performanceGraphInterval: number;
  p1Name: string;
  p2Name: string;
}

// function yesNoToBool(str?: string) {
//   if (!str || str === undefined) return false;
//   return str === "yes" ? true : false;
// }

export default function Settings() {
  const { previousStep } = useWizard();
  const navigate = useNavigate();
  const labelColor = useColorModeValue("gray.700", "gray.50");
  const stackBg = useColorModeValue("white", "gray.700");
  const subTextColor = useColorModeValue("gray.600", "gray.400");
  const saveColor = useColorModeValue("gray.50", "gray.900");
  const {
    handleSubmit,
    register,
    // formState: { isSubmitting },
  } = useForm<SettingsForm>({
    defaultValues: {
      distanceCoverageGraphInterval: 1000,
      velocityGraphInterval: 1000,
      movementPatternSize: 3,
      movementPatternInterval: 1000,
      performanceGraphInterval: 1000,
      p1Name: "P1",
      p2Name: "P2",
    },
  });
  const { state, capturedImage, camera } = useStateContext();

  const { mutate, isLoading: mutationLoading } = useMutation({
    ...createPOCStream.useMutation({}),
    onSuccess: (resp) => {
      navigate(getRoutePath(`/casualanalysis/${resp.id}/control-live`));
    },
  });

  if (mutationLoading) return <Loading text="Saving..." />;

  const onSubmit = (data: SettingsForm) => {
    const req = new CreatePOCStreamRequest({
      camera,
      court: state.request.court,
      image: capturedImage,
      p1Name: data.p1Name,
      p2Name: data.p2Name,
    });

    mutate(req);
  };

  return (
    <SimpleGrid
      display={{ base: "initial", md: "grid" }}
      columns={{ md: 3 }}
      spacing={{ md: 6 }}
      pt={4}
    >
      <GridItem colSpan={{ md: 1 }}>
        <Box px={[4, 0]}>
          <Heading fontSize="lg" fontWeight="medium" lineHeight="6">
            Live POCStream
          </Heading>
          <Text mt={1} fontSize="sm" color={subTextColor}>
            Update the Settings as per your needs
          </Text>
        </Box>
      </GridItem>
      <GridItem mt={[5, null, 0]} colSpan={{ md: 2 }}>
        <chakra.form
          onSubmit={handleSubmit(onSubmit)}
          shadow="base"
          rounded={[null, "md"]}
          overflow={{ sm: "hidden" }}
        >
          <Stack px={4} py={5} p={[null, 6]} bg={stackBg} spacing={6}>
            <SimpleGrid columns={6} spacing={6}>
              <FormControl as={GridItem} colSpan={[6, 3]}>
                <FormLabel fontSize="sm" fontWeight="md" color={labelColor}>
                  P1 Name
                </FormLabel>
                <Input {...register("p1Name")} />
              </FormControl>

              <FormControl as={GridItem} colSpan={[6, 3]}>
                <FormLabel fontSize="sm" fontWeight="md" color={labelColor}>
                  P2 Name
                </FormLabel>
                <Input {...register("p2Name")} />
              </FormControl>
            </SimpleGrid>
          </Stack>
          <Box px={{ base: 4, sm: 6 }} py={3} bg={saveColor} textAlign="right">
            <Button
              type="submit"
              colorScheme="red"
              _focus={{ shadow: "" }}
              fontWeight="md"
              mr={4}
              onClick={() => {
                previousStep();
              }}
            >
              Go Back
            </Button>
            <Button
              type="submit"
              colorScheme="messenger"
              _focus={{ shadow: "" }}
              fontWeight="md"
            >
              Start
            </Button>
          </Box>
        </chakra.form>
      </GridItem>
    </SimpleGrid>
  );
}
