import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useStateContext } from "./state";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  getPOCStream,
  endPOCStream,
} from "proto/pocstream/v1/poc_api-POCStreamAPI_connectquery";
import { serveBasePath } from "server-props";
import { EndPOCStreamRequest } from "proto/pocstream/v1/poc_api_pb";
import { useRef, useState } from "react";
import { Loading } from "components";
import { useNavigate } from "react-router-dom";
import ReactHlsPlayer from "react-hls-player";
import { RWebShare } from "react-web-share";

export default function ControlLivePOCStream() {
  const { pocstream } = useStateContext();
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();

  const fullVideoRef = useRef<HTMLVideoElement>(null);
  const hlsLiveVideoUrl = `${serveBasePath}/api/files/pocstreams/${pocstream.id}/full-footage/index.m3u8`;

  const { mutate: commandMutate, isLoading: commandIsLoading } = useMutation({
    ...endPOCStream.useMutation({}),
    onSuccess: (resp) => {
      queryClient.invalidateQueries(
        getPOCStream.getQueryKey({ id: pocstream?.id ?? "" })
      );

      toast({
        status: "success",
        title: "Live Match Ended!",
        description: "",
      });

      navigate(`/casualanalysis/${pocstream.id}`, { replace: true });
    },
  });

  const [frameNo] = useState(-1);

  if (commandIsLoading) return <Loading text="Updating..." />;

  if ((pocstream.endedAt?.seconds ?? 0) > 0) {
    return (
      <Center>
        <Text size={"lg"}>POCStream Ended</Text>
      </Center>
    );
  }

  return (
    <>
      {pocstream.isLive === true && (
        <ReactHlsPlayer
          playerRef={fullVideoRef}
          height="100%"
          width="100%"
          src={hlsLiveVideoUrl}
          controls
          autoPlay={true}
          style={{ borderRadius: 4 }}
        />
      )}
      <Stack pt={4}>
        <Box>
          <Button
            size="lg"
            w="full"
            colorScheme="red"
            onClick={() => {
              commandMutate(
                new EndPOCStreamRequest({
                  id: pocstream.id,
                  frameNo,
                })
              );
            }}
          >
            End
          </Button>
        </Box>
      </Stack>

      <Stack pt={4}>
        <Box>
          <RWebShare
            data={{
              text: "Reticulo Studio",
              url: window.location
                .toString()
                .replaceAll("/casualanalysis", "/view-casualanalysis")
                .replaceAll("/control-live", ""),
              title: "Reticulo Studio",
            }}
            onClick={() => console.log("shared successfully!")}
          >
            <Button
              size="lg"
              w="full"
              colorScheme="messenger"
              onClick={() => {
                commandMutate(
                  new EndPOCStreamRequest({
                    id: pocstream.id,
                    frameNo,
                  })
                );
              }}
            >
              Share
            </Button>
          </RWebShare>
        </Box>
      </Stack>
    </>
  );
}
