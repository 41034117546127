import { useRef } from "react";
import ReactHlsPlayer from "react-hls-player";
import { useParams } from "react-router-dom";
import { serveBasePath } from "server-props";

export default function LivePOCStreamPage() {
  const { id } = useParams<{ id: string }>();

  const fullVideoRef = useRef<HTMLVideoElement>(null);
  const hlsLiveVideoUrl = `${serveBasePath}/api/files/pocstreams/${id}/full-footage/index.m3u8`;

  return (
    <ReactHlsPlayer
      playerRef={fullVideoRef}
      height="100%"
      width="100%"
      src={hlsLiveVideoUrl}
      controls
      autoPlay={true}
      style={{ borderRadius: 4 }}
    />
  );
}
