import { ReactNode } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useGlobalStateContext } from "hooks/useGlobalState";
import { AccountDeleteRequested, DisplayError, Loading } from "components";
import { useLogout } from "hooks";
import QRCode from "react-qr-code";
import {
  Avatar,
  Box,
  chakra,
  Flex,
  HStack,
  IconButton,
  MenuButton,
  MenuItem,
  MenuList,
  Menu,
  useColorModeValue,
  useDisclosure,
  Modal,
  Center,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  MenuGroup,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  Icon,
  SimpleGrid,
  GridItem,
} from "@chakra-ui/react";
import React from "react";
import {
  AiOutlineMenu,
  AiOutlinePlusCircle,
  // AiOutlineQrcode,
} from "react-icons/ai";
import { TbTournament } from "react-icons/tb";
import {
  MdLinkedCamera,
  MdLiveTv,
  MdOutlinePrivacyTip,
  MdSpaceDashboard,
} from "react-icons/md";
import { FaChalkboardTeacher } from "react-icons/fa";
import { protoUserName } from "helpers";
import { CgMediaLive, CgProfile } from "react-icons/cg";
import { HiOutlineLogout } from "react-icons/hi";
import { appName } from "env";
import { BsShareFill } from "react-icons/bs";
import { RWebShare } from "react-web-share";
import { Code } from "@bufbuild/connect";
import { isPublic } from "config";

const allLinks = [
  {
    label: "Dashboard",
    icon: <MdSpaceDashboard />,
    iconPlain: MdSpaceDashboard,
    link: "/",
    match: new RegExp(/^[/]$/g),
  },
  {
    label: "Recordings",
    icon: <FaChalkboardTeacher />,
    iconPlain: FaChalkboardTeacher,
    link: "/recordings",
    match: new RegExp(/[/]recording/g),
  },
  {
    label: "Streams",
    icon: <MdLiveTv />,
    iconPlain: MdLiveTv,
    link: "/streams",
    match: new RegExp(/[/]stream/g),
  },
  {
    label: "Start Live",
    icon: <CgMediaLive />,
    iconPlain: CgMediaLive,
    link: "/start-live",
    match: new RegExp(/[/]start-live/),
  },
  {
    label: "Tournaments",
    icon: <TbTournament />,
    iconPlain: TbTournament,
    link: "/tournaments",
    match: new RegExp(/[/]tournament/),
  },
  {
    label: "Casual Analysis",
    icon: <MdLinkedCamera />,
    iconPlain: MdLinkedCamera,
    link: "/casualanalysis",
    match: new RegExp(/[/]casualanalysis/),
  },
  {
    label: "Privacy Policy",
    icon: <MdOutlinePrivacyTip />,
    iconPlain: MdOutlinePrivacyTip,
    link: "https://reticulo.in/privacy",
    match: new RegExp(/^[/]$/g),
    external: true,
  },
];

export const MainLayout = ({ children }: { children: ReactNode }) => {
  const bg = useColorModeValue("gray.50", "gray.800");
  const sidebar = useDisclosure();
  const { loaded, data, error, isError, isLandscape } = useGlobalStateContext();
  const location = useLocation();
  const onLogout = useLogout();
  const { isOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  if (!loaded) {
    return <Loading />;
  }
  if (isError) {
    if (error?.code === Code.Unavailable) {
      return <AccountDeleteRequested error={error} />;
    }
    return <DisplayError error={error} />;
  }

  const NavItem = (props: any) => {
    const { icon, children, ...rest } = props;
    return (
      <Flex
        align="center"
        px="4"
        pl="4"
        py="3"
        cursor="pointer"
        color="inherit"
        _dark={{
          color: "gray.400",
        }}
        _hover={{
          bg: "gray.100",
          _dark: {
            bg: "gray.900",
          },
          color: "gray.900",
        }}
        role="group"
        fontWeight="semibold"
        transition=".15s ease"
        {...rest}
      >
        {icon && <Icon mx="2" fontSize="lg" as={icon} color="messenger.500" />}
        {children}
      </Flex>
    );
  };

  const SidebarContent = (props: any) => (
    <Box
      as="nav"
      pos="fixed"
      top="0"
      left="0"
      zIndex="sticky"
      h="full"
      pb="10"
      overflowX="hidden"
      overflowY="auto"
      bg="white"
      _dark={{
        bg: "gray.800",
      }}
      border
      color="inherit"
      borderRightWidth="1px"
      w="60"
      {...props}
    >
      <Flex px="4" py="5" align="center" justifyContent={""}>
        <Text
          fontSize="2xl"
          ml="2"
          color="messenger.500"
          _dark={{
            color: "white",
          }}
          fontWeight="semibold"
        >
          Reticulo {appName}
        </Text>
        {/* <IconButton
          ml={4}
          size="md"
          icon={<AiOutlineClose />}
          aria-label="Open Menu"
          onClick={isOpen ? onClose : onOpen}
        /> */}
      </Flex>
      <Flex
        direction="column"
        as="nav"
        fontSize="sm"
        color="gray.600"
        aria-label="Main Navigation"
      >
        {allLinks
          .filter((l) => !!data)
          .map((l) => {
            if (l.external === true) {
              return (
                <NavItem
                  icon={l.iconPlain}
                  key={l.link}
                  onClick={() => {
                    window.open(l.link);
                  }}
                >
                  <Text textColor={"messenger.500"} fontSize="lg">
                    {l.label}
                  </Text>
                </NavItem>
              );
            }
            return (
              <Link to={l.link} onClick={sidebar.onClose} key={l.link}>
                <NavItem icon={l.iconPlain}>
                  <Text textColor={"messenger.500"} fontSize="lg">
                    {l.label}
                  </Text>
                </NavItem>
              </Link>
            );
          })}
      </Flex>
    </Box>
  );

  return (
    <Box bg={bg} overflow="hidden" minH="100vh">
      {!isPublic && (
        <Drawer
          isOpen={sidebar.isOpen}
          onClose={sidebar.onClose}
          placement="left"
        >
          <DrawerOverlay />
          <DrawerContent>
            <SidebarContent w="full" borderRight="none" />
          </DrawerContent>
        </Drawer>
      )}
      <React.Fragment>
        <chakra.header
          display={!location.pathname.includes("/rallies") ? "block" : "none"}
          bg={"brand.500"}
          w="full"
          px={{
            base: 2,
            sm: 4,
          }}
          roundedBottom={"lg"}
        >
          <Flex
            alignItems="center"
            // justifyContent="space-between"
            mx="auto"
            h={{
              base: isLandscape ? "14vh" : "9vh",
              lg: "7vh",
            }}
          >
            <HStack display="flex" spacing={3} zIndex={500}>
              <Box px={isPublic ? 1 : 0}>
                <IconButton
                  hidden={isPublic}
                  aria-label="Open menu"
                  fontSize="20px"
                  color="white"
                  _dark={{
                    color: "inherit",
                  }}
                  _hover={{
                    bgColor: "",
                  }}
                  variant="filled"
                  icon={<AiOutlineMenu />}
                  onClick={sidebar.onOpen}
                />
              </Box>
              <Box display={{ base: "none", lg: "block" }}>
                <Center
                  fontSize={["2xl", "3xl", "3xl"]}
                  color="white"
                  as={Link}
                  to={"/"}
                >
                  {appName}
                </Center>
              </Box>
            </HStack>
            <HStack display={{ lg: "none" }}>
              <Text
                fontSize={["2xl", "3xl", "3xl"]}
                color="white"
                as={Link}
                to={"/"}
              >
                {appName}
              </Text>
            </HStack>
            <HStack
              w="100%"
              justifyContent="flex-end"
              spacing={1}
              // display={sidebar.isOpen ? "none" : "flex"}
              alignItems="flex-end"
            >
              <SimpleGrid columns={isPublic ? 1 : 3}>
                <GridItem colSpan={1}>
                  <RWebShare
                    data={{
                      text: appName,
                      url: window.location
                        .toString()
                        .includes("/casualanalysis")
                        ? window.location
                            .toString()
                            .replaceAll(
                              "/casualanalysis",
                              "/view-casualanalysis"
                            )
                            .replaceAll("/control-live", "")
                        : window.location.toString(),
                      title: appName,
                    }}
                    onClick={() => console.log("shared successfully!")}
                  >
                    <IconButton
                      aria-label=""
                      rounded={"3xl"}
                      bg={"transparent"}
                      colorScheme="whiteAlpha"
                    >
                      <chakra.span pos="relative" display="inline-block">
                        <BsShareFill />
                      </chakra.span>
                    </IconButton>
                  </RWebShare>
                </GridItem>
                {location.pathname.includes("/recordings") && (
                  <GridItem colSpan={1}>
                    <IconButton
                      aria-label=""
                      rounded={"3xl"}
                      onClick={() => {
                        navigate("/new-recording");
                      }}
                      bg={"transparent"}
                      colorScheme="whiteAlpha"
                    >
                      <chakra.span pos="relative" display="inline-block">
                        <AiOutlinePlusCircle />
                      </chakra.span>
                    </IconButton>
                  </GridItem>
                )}

                {location.pathname.includes("/streams") && (
                  <GridItem colSpan={1}>
                    <IconButton
                      aria-label=""
                      rounded={"3xl"}
                      onClick={() => {
                        navigate("/start-stream");
                      }}
                      bg={"transparent"}
                      colorScheme="whiteAlpha"
                    >
                      <chakra.span pos="relative" display="inline-block">
                        <AiOutlinePlusCircle />
                      </chakra.span>
                    </IconButton>
                  </GridItem>
                )}

                {location.pathname.includes("/casualanalysis") && (
                  <GridItem colSpan={1}>
                    <IconButton
                      aria-label=""
                      rounded={"3xl"}
                      onClick={() => {
                        navigate("/start-casualanalysis");
                      }}
                      bg={"transparent"}
                      colorScheme="whiteAlpha"
                    >
                      <chakra.span pos="relative" display="inline-block">
                        <AiOutlinePlusCircle />
                      </chakra.span>
                    </IconButton>
                  </GridItem>
                )}

                {location.pathname.includes("/tournaments") && (
                  <GridItem colSpan={1}>
                    <IconButton
                      aria-label=""
                      rounded={"3xl"}
                      onClick={() => {
                        navigate("/new-tournament");
                      }}
                      bg={"transparent"}
                      colorScheme="whiteAlpha"
                    >
                      <chakra.span pos="relative" display="inline-block">
                        <AiOutlinePlusCircle />
                      </chakra.span>
                    </IconButton>
                  </GridItem>
                )}

                {!isPublic && (
                  <GridItem colSpan={1}>
                    <Center h="full">
                      <Menu>
                        <MenuButton
                          border="solid"
                          borderColor="messenger.600"
                          as={IconButton}
                          rounded={"full"}
                          variant={"link"}
                          cursor={"pointer"}
                          minW={0}
                          defaultValue={-1}
                        >
                          <Avatar bg="messenger.900" size="xs" />
                        </MenuButton>
                        <MenuList>
                          <MenuGroup
                            title={`Welcome ${protoUserName(data?.user)}!`}
                          >
                            <MenuItem
                              onClick={() => navigate("/profile")}
                              icon={<CgProfile />}
                            >
                              My Profile
                            </MenuItem>
                            {/* <MenuItem
                              onClick={onOpen}
                              icon={<AiOutlineQrcode />}
                            >
                              Show my QR
                            </MenuItem> */}
                            <MenuItem
                              onClick={onLogout}
                              icon={<HiOutlineLogout />}
                            >
                              Logout
                            </MenuItem>
                          </MenuGroup>
                        </MenuList>
                      </Menu>
                    </Center>
                  </GridItem>
                )}
              </SimpleGrid>
            </HStack>
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent shadow={"lg"} maxW="80vw">
                <ModalHeader>Your QR Code</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Center pb={12}>
                    <QRCode value={JSON.stringify(data?.user ?? "{}")} />
                  </Center>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Flex>
        </chakra.header>
      </React.Fragment>
      {children}
    </Box>
  );
};
