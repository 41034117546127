import { Box, Center, Heading } from "@chakra-ui/react";
import CourtSelector from "./CourtSelector";
import Header from "./Header";
import { Wizard } from "react-use-wizard";
import { StateProvider } from "./state";
import Verification from "./Verification";
import Settings from "./Settings";
import { CreatePOCStreamRequest } from "proto/pocstream/v1/poc_api_pb";
import CameraSelect from "./Camera";

export default function LivePOCStream() {
  return (
    <StateProvider
      initialState={{
        request: new CreatePOCStreamRequest(),
      }}
    >
      <Box mt={[10, 2]} mx={{ base: 2, md: 4, lg: 8 }}>
        <Center>
          <Box w="90%" textAlign={"center"}>
            <Heading size={"xl"}>Start Match</Heading>
          </Box>
        </Center>
        <Wizard header={<Header />} startIndex={0}>
          <CameraSelect />
          <CourtSelector />
          <Verification />
          <Settings />
        </Wizard>
      </Box>
    </StateProvider>
  );
}
