// @generated by protoc-gen-connect-query v0.2.0 with parameter "target=ts"
// @generated from file pocstream/v1/poc_api.proto (package pocstream.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { createQueryService } from "@bufbuild/connect-query";
import { MethodKind } from "@bufbuild/protobuf";
import { CreatePOCStreamRequest, CreatePOCStreamResponse, DeletePOCStreamRequest, DeletePOCStreamResponse, EndPOCStreamRequest, EndPOCStreamResponse, GetPOCStreamRequest, GetPOCStreamResponse, ListPOCStreamsRequest, ListPOCStreamsResponse } from "./poc_api_pb.js";

export const typeName = "pocstream.v1.POCStreamAPI";

/**
 * @generated from rpc pocstream.v1.POCStreamAPI.ListPOCStreams
 */
export const listPOCStreams = createQueryService({
  service: {
    methods: {
      listPOCStreams: {
        name: "ListPOCStreams",
        kind: MethodKind.Unary,
        I: ListPOCStreamsRequest,
        O: ListPOCStreamsResponse,
      },
    },
    typeName: "pocstream.v1.POCStreamAPI",
  },
}).listPOCStreams;

/**
 * @generated from rpc pocstream.v1.POCStreamAPI.GetPOCStream
 */
export const getPOCStream = createQueryService({
  service: {
    methods: {
      getPOCStream: {
        name: "GetPOCStream",
        kind: MethodKind.Unary,
        I: GetPOCStreamRequest,
        O: GetPOCStreamResponse,
      },
    },
    typeName: "pocstream.v1.POCStreamAPI",
  },
}).getPOCStream;

/**
 * @generated from rpc pocstream.v1.POCStreamAPI.DeletePOCStream
 */
export const deletePOCStream = createQueryService({
  service: {
    methods: {
      deletePOCStream: {
        name: "DeletePOCStream",
        kind: MethodKind.Unary,
        I: DeletePOCStreamRequest,
        O: DeletePOCStreamResponse,
      },
    },
    typeName: "pocstream.v1.POCStreamAPI",
  },
}).deletePOCStream;

/**
 * @generated from rpc pocstream.v1.POCStreamAPI.CreatePOCStream
 */
export const createPOCStream = createQueryService({
  service: {
    methods: {
      createPOCStream: {
        name: "CreatePOCStream",
        kind: MethodKind.Unary,
        I: CreatePOCStreamRequest,
        O: CreatePOCStreamResponse,
      },
    },
    typeName: "pocstream.v1.POCStreamAPI",
  },
}).createPOCStream;

/**
 * @generated from rpc pocstream.v1.POCStreamAPI.EndPOCStream
 */
export const endPOCStream = createQueryService({
  service: {
    methods: {
      endPOCStream: {
        name: "EndPOCStream",
        kind: MethodKind.Unary,
        I: EndPOCStreamRequest,
        O: EndPOCStreamResponse,
      },
    },
    typeName: "pocstream.v1.POCStreamAPI",
  },
}).endPOCStream;
