// @generated by protoc-gen-es v1.2.0 with parameter "target=js+dts"
// @generated from file pocstream/v1/poc_api.proto (package pocstream.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Rally, Statistics } from "../../recording/v1/recording_api_pb.js";
import { Court } from "../../base/v1/location_pb.js";

/**
 * @generated from enum pocstream.v1.CommandType
 */
export const CommandType = proto3.makeEnum(
  "pocstream.v1.CommandType",
  [
    {no: 0, name: "COMMAND_TYPE_INVALID", localName: "INVALID"},
    {no: 1, name: "COMMAND_TYPE_END", localName: "END"},
  ],
);

/**
 * @generated from enum pocstream.v1.POCStreamState
 */
export const POCStreamState = proto3.makeEnum(
  "pocstream.v1.POCStreamState",
  [
    {no: 0, name: "POCSTREAM_STATE_INVALID"},
    {no: 1, name: "POCSTREAM_STATE_ACTIVE"},
    {no: 2, name: "POCSTREAM_STATE_PROCESSING"},
    {no: 3, name: "POCSTREAM_STATE_DONE"},
  ],
);

/**
 * @generated from message pocstream.v1.Command
 */
export const Command = proto3.makeMessageType(
  "pocstream.v1.Command",
  () => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(CommandType) },
    { no: 2, name: "frame_no", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "is_player_pos_inverted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message pocstream.v1.POCStream
 */
export const POCStream = proto3.makeMessageType(
  "pocstream.v1.POCStream",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "p1_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "p2_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "is_live", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "state", kind: "enum", T: proto3.getEnumType(POCStreamState) },
    { no: 6, name: "thumbnail_file_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "p1_statistics", kind: "message", T: Statistics },
    { no: 8, name: "p2_statistics", kind: "message", T: Statistics },
    { no: 9, name: "rallies", kind: "message", T: Rally, repeated: true },
    { no: 10, name: "ended_at", kind: "message", T: Timestamp },
    { no: 11, name: "processing_ended_at", kind: "message", T: Timestamp },
    { no: 12, name: "created_at", kind: "message", T: Timestamp },
    { no: 13, name: "updated_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message pocstream.v1.ListPOCStreamsRequest
 */
export const ListPOCStreamsRequest = proto3.makeMessageType(
  "pocstream.v1.ListPOCStreamsRequest",
  [],
);

/**
 * @generated from message pocstream.v1.ListPOCStreamsResponse
 */
export const ListPOCStreamsResponse = proto3.makeMessageType(
  "pocstream.v1.ListPOCStreamsResponse",
  () => [
    { no: 1, name: "pocstreams", kind: "message", T: POCStream, repeated: true },
  ],
);

/**
 * @generated from message pocstream.v1.GetPOCStreamRequest
 */
export const GetPOCStreamRequest = proto3.makeMessageType(
  "pocstream.v1.GetPOCStreamRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message pocstream.v1.GetPOCStreamResponse
 */
export const GetPOCStreamResponse = proto3.makeMessageType(
  "pocstream.v1.GetPOCStreamResponse",
  () => [
    { no: 1, name: "pocstream", kind: "message", T: POCStream },
  ],
);

/**
 * @generated from message pocstream.v1.DeletePOCStreamRequest
 */
export const DeletePOCStreamRequest = proto3.makeMessageType(
  "pocstream.v1.DeletePOCStreamRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "delete_files", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message pocstream.v1.DeletePOCStreamResponse
 */
export const DeletePOCStreamResponse = proto3.makeMessageType(
  "pocstream.v1.DeletePOCStreamResponse",
  [],
);

/**
 * @generated from message pocstream.v1.CreatePOCStreamRequest
 */
export const CreatePOCStreamRequest = proto3.makeMessageType(
  "pocstream.v1.CreatePOCStreamRequest",
  () => [
    { no: 1, name: "camera", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "p1_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "p2_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "court", kind: "message", T: Court },
  ],
);

/**
 * @generated from message pocstream.v1.CreatePOCStreamResponse
 */
export const CreatePOCStreamResponse = proto3.makeMessageType(
  "pocstream.v1.CreatePOCStreamResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message pocstream.v1.EndPOCStreamRequest
 */
export const EndPOCStreamRequest = proto3.makeMessageType(
  "pocstream.v1.EndPOCStreamRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "frame_no", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message pocstream.v1.EndPOCStreamResponse
 */
export const EndPOCStreamResponse = proto3.makeMessageType(
  "pocstream.v1.EndPOCStreamResponse",
  [],
);

