import constate from "constate";
import { useMemo, useState } from "react";
import { POCStream } from "proto/pocstream/v1/poc_api_pb";
import {
  GeneralStatistics,
  HitStatistics,
} from "proto/recording/v1/recording_api_pb";

interface MatchGameStatsAndReport {
  p1GeneralStatistics?: GeneralStatistics;
  p2GeneralStatistics?: GeneralStatistics;
  p1HitStatistics?: HitStatistics;
  p2HitStatistics?: HitStatistics;
}

const useMainState = (props: { pocstream: POCStream }) => {
  const [pocstream] = useState(props.pocstream);
  const [currPlayer, setCurrPlayer] = useState<"p1" | "p2" | "combined">("p1");
  const [searchTerm, setSearchTerm] = useState("");
  const [statSelection, setStatSelection] = useState<number>(0);
  const generalHitStatsAndReport: MatchGameStatsAndReport = useMemo(() => {
    if (!pocstream) {
      return {};
    }
    var p1 = pocstream?.p1Statistics;
    var p2 = pocstream?.p2Statistics;
    if (statSelection > 0) {
      const rally = pocstream.rallies[statSelection - 1];
      p1 = rally?.p1RallyStatistics;
      p2 = rally?.p2RallyStatistics;
    }

    return {
      p1GeneralStatistics: p1?.generalStatistics,
      p2GeneralStatistics: p2?.generalStatistics,
      p1HitStatistics: p1?.hitStatistics,
      p2HitStatistics: p2?.hitStatistics,
    };
  }, [pocstream, statSelection]);

  return {
    pocstream,
    currPlayer,
    setCurrPlayer,
    searchTerm,
    setSearchTerm,
    statSelection,
    setStatSelection,
    generalHitStatsAndReport,
  };
};

const [StateProvider, useStateContext] = constate(useMainState);

export { StateProvider, useStateContext };
