import constate from "constate";
import { useState } from "react";
import { CreatePOCStreamRequest } from "proto/pocstream/v1/poc_api_pb";
import { Court, Location } from "proto/base/v1/location_pb";

interface State {
  request: CreatePOCStreamRequest;
}

export default function useProcessPOCStream(props: { initialState: State }) {
  const [capturedImage, setCapturedImage] = useState("");
  const [camera, setCamera] = useState("");
  const [state, setState] = useState<State>(props.initialState);
  const setCourt = (
    tlX: number,
    tlY: number,
    trX: number,
    trY: number,
    blX: number,
    blY: number,
    brX: number,
    brY: number,
    mlY: number,
    mrY: number
  ) => {
    const tl = new Location({ x: tlX, y: tlY });
    const tr = new Location({ x: trX, y: trY });
    const bl = new Location({ x: blX, y: blY });
    const br = new Location({ x: brX, y: brY });
    const ml = new Location({ x: 0, y: mlY });
    const mr = new Location({ x: 5000, y: mrY });
    state.request.court = new Court({
      topLeft: tl,
      topRight: tr,
      bottomLeft: bl,
      bottomRight: br,
      middleLeft: ml,
      middleRight: mr,
    });

    setState(state);
  };
  return {
    state,
    setState,
    setCourt,
    capturedImage,
    setCapturedImage,
    camera,
    setCamera,
  };
}

const [StateProvider, useStateContext] = constate(useProcessPOCStream);

export { StateProvider, useStateContext };
