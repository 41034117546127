import { useMutation, useQuery } from "@tanstack/react-query";
import { useStateContext } from "./state";
import {
  Center,
  Box,
  Heading,
  RadioGroup,
  Text,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Flex,
  SimpleGrid,
  GridItem,
  Stack,
} from "@chakra-ui/react";
import { AiFillEye } from "react-icons/ai";
import { Loading } from "components";
import { Image } from "@chakra-ui/image";
import { Button } from "@chakra-ui/button";
import { useWizard } from "react-use-wizard";
import { captureCameraFrame } from "proto/recording/v1/recording_api-RecordingAPI_connectquery";
import { listCameras } from "proto/setting/v1/setting_api-SettingAPI_connectquery";
import { BsDot } from "react-icons/bs";

export default function CameraSelect() {
  const { nextStep } = useWizard();
  const {
    isOpen: isPreviewOpen,
    onOpen: onPreviewOpen,
    onClose: onPreviewClose,
  } = useDisclosure();

  const { setCapturedImage, capturedImage, camera, setCamera } =
    useStateContext();

  const { data, isLoading, isFetching, isError, error } = useQuery(
    listCameras.useQuery({})
  );

  const { mutate: previewMutate, isLoading: previewMutationLoading } =
    useMutation({
      ...captureCameraFrame.useMutation(),
      onSuccess: (resp) => {
        setCapturedImage(resp.image);
        onPreviewOpen();
      },
    });

  if ((isLoading || isFetching) && !data) return <Loading />;
  if (previewMutationLoading) return <Loading text="Generating..." />;

  if (isError)
    return (
      <Text
        as="h3"
        py={2}
        textAlign="center"
        fontWeight="bold"
        textTransform="uppercase"
        color="red.500"
        letterSpacing={1}
      >
        An error has occurred: {error?.message}{" "}
      </Text>
    );

  return (
    <>
      <Modal isOpen={isPreviewOpen} onClose={onPreviewClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Center>
              <Flex>
                <Image
                  zIndex={-1}
                  src={capturedImage}
                  alt={""}
                  roundedTop="lg"
                />
              </Flex>
            </Center>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onPreviewClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Center>
        <Box w="90%">
          <Box minH={"50vh"} overflow={"scroll"}>
            <Center p={4}>
              <Heading
                size={"md"}
                textAlign={"center"}
                justifyContent={"center"}
              >
                Select Camera
              </Heading>
            </Center>
            <Center w="100%">
              <RadioGroup
                mt={4}
                w="90%"
                minW="200px"
                onChange={(v) => setCamera(v)}
                value={camera}
              >
                {data.cameras.map((c, index) => (
                  <SimpleGrid
                    w="full"
                    columns={10}
                    spacing={2}
                    cursor={c.available ? "grab" : "not-allowed"}
                    onClick={c.available ? () => setCamera(c.id) : undefined}
                    py={2}
                  >
                    <GridItem colSpan={1}>
                      <Text>
                        <BsDot />
                      </Text>
                    </GridItem>
                    <GridItem colSpan={7}>
                      <Stack spacing={0}>
                        <Text overflow={"auto"}>
                          {c.name} ({c.fps} FPS)
                        </Text>
                        {!c.available && (
                          <Text color={"red.500"}>Camera in use</Text>
                        )}
                      </Stack>
                    </GridItem>
                    <GridItem colSpan={1}>
                      <Text
                        hidden={!c.available}
                        fontSize="2xl"
                        pointerEvents={camera !== c.id ? "none" : "all"}
                        opacity={camera !== c.id ? 0.5 : 1}
                      >
                        <AiFillEye
                          onClick={() => previewMutate({ camera: c.id })}
                        />
                      </Text>
                    </GridItem>
                  </SimpleGrid>
                ))}
              </RadioGroup>
            </Center>
          </Box>
          <Button
            isDisabled={camera === ""}
            float={"right"}
            alignContent={"flex-end"}
            onClick={() => nextStep()}
          >
            Next
          </Button>
        </Box>
      </Center>
    </>
  );
}
